import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Mic, MicOff, Star, AlertCircle } from 'lucide-react';
import { RealtimeClient } from '@openai/realtime-api-beta';
import { ItemType } from '@openai/realtime-api-beta/dist/lib/client.js';
import { WavRecorder, WavStreamPlayer } from '../lib/wavtools/index.js';
import { WavRenderer } from '../utils/wav_renderer';
import { createEmployeeResumeHandler } from '../tools/employeeResumeTool';
import { employeeResumeTool } from '../tools/employeeResumeTool';
import './VoiceReferenceConsole.scss';
import { createAccessCodeHandler } from '../tools/accessCodeTool';
import { accessCodeTool } from '../tools/accessCodeTool';
import { instructions } from '../utils/conversation_config';

const LOCAL_RELAY_SERVER_URL = process.env.REACT_APP_LOCAL_RELAY_SERVER_URL || '';

// Tool manager singleton
class ToolManager {
  private static instance: ToolManager;
  private initialized: boolean = false;
  
  private constructor() {}
  
  static getInstance(): ToolManager {
    if (!ToolManager.instance) {
      ToolManager.instance = new ToolManager();
    }
    return ToolManager.instance;
  }
  
  isInitialized(): boolean {
    return this.initialized;
  }
  
  markInitialized(): void {
    this.initialized = true;
  }
}

const VoiceReferenceConsole = () => {
  // UI State
  const [isHolding, setIsHolding] = useState(false);
  const [isAISpeaking, setIsAISpeaking] = useState(true);
  const [seconds, setSeconds] = useState(0);
  const [showTips, setShowTips] = useState(true);
  const [showCosts, setShowCosts] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [metrics, setMetrics] = useState({ revenue: '0.00', cost: '0.00', profit: '0.00', margin: '0.0' });
  const RATE_PER_MINUTE = 1.50;
  const COST_PER_MINUTE = 0.45; // $0.45 per minute

  const [isAIActuallySpeaking, setIsAIActuallySpeaking] = useState(false);
  const [aiStatus, setAIStatus] = useState<'speaking' | 'waiting' | 'idle'>('idle');
  const [totalTime, setTotalTime] = useState(0);
  const [cost, setCost] = useState(0);
  
  // Audio and Connection State
  const [isConnected, setIsConnected] = useState(false);
  const [items, setItems] = useState<ItemType[]>([]);
  const [realtimeEvents, setRealtimeEvents] = useState<any[]>([]);

  // Audio Refs
  const wavRecorderRef = useRef<WavRecorder>(new WavRecorder({ sampleRate: 24000 }));
  const wavStreamPlayerRef = useRef<WavStreamPlayer>(new WavStreamPlayer({ sampleRate: 24000 }));
  const clientRef = useRef<RealtimeClient | null>(null);
  
  // Canvas Refs for Visualization
  const clientCanvasRef = useRef<HTMLCanvasElement>(null);
  const serverCanvasRef = useRef<HTMLCanvasElement>(null);

  const calculateMetrics = (secs: any) => {
    const minutesFraction = secs / 60;
    const revenue = minutesFraction * RATE_PER_MINUTE;
    const cost = minutesFraction * COST_PER_MINUTE;
    const profit = revenue - cost;
    const margin = revenue > 0 ? (profit / revenue) * 100 : 0;

    return {
      revenue: revenue.toFixed(2),
      cost: cost.toFixed(2),
      profit: profit.toFixed(2),
      margin: margin.toFixed(1)
    };
  };

  const topics = [
    { id: 1, topic: "Role", status: "completed" },
    { id: 2, topic: "Projects", status: "completed" },
    { id: 3, topic: "Skills", status: "active" },
    { id: 4, topic: "Team", status: "pending" },
    { id: 5, topic: "Growth", status: "pending" }
  ];

  // Initialize tools
  const initializeTools = (client: RealtimeClient): void => {
    const toolManager = ToolManager.getInstance();
    
    if (toolManager.isInitialized()) {
      console.log('Tools already initialized');
      return;
    }
    
    console.log('Initializing tools...');

    client.updateSession({ 
        instructions: instructions,
        input_audio_transcription: { model: 'whisper-1' }
      });

    
    // Add reference-specific tools
    const tools = [
        { tool: employeeResumeTool, handler: createEmployeeResumeHandler },
        { tool: accessCodeTool, handler: createAccessCodeHandler }
      ];
    
    tools.forEach(({ tool, handler }) => {
      client.addTool(tool, handler);
    });
    
    toolManager.markInitialized();
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    setShowCosts(searchParams.get('test') === 'true');
  }, []);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isHolding || isAIActuallySpeaking) {
      interval = setInterval(() => {
        setTotalTime(prev => prev + 1);
        setMetrics(calculateMetrics(totalTime + 1));
        // setCost(prev => Number(((totalTime + 1) * (COST_PER_MINUTE / 60)).toFixed(2)));
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isHolding, isAIActuallySpeaking, totalTime]);

  // Initialize client and set up event handlers
  useEffect(() => {
    const apiKey = LOCAL_RELAY_SERVER_URL
      ? ''
      : localStorage.getItem('tmp::voice_api_key');

    const client = new RealtimeClient({
      url: LOCAL_RELAY_SERVER_URL || undefined,
      apiKey: LOCAL_RELAY_SERVER_URL ? undefined : apiKey || '',
      dangerouslyAllowAPIKeyInBrowser: !LOCAL_RELAY_SERVER_URL
    });
    
    initializeTools(client);
    clientRef.current = client;
    
    // Set up event handlers
    client.on('realtime.event', (realtimeEvent: any) => {
      setRealtimeEvents(prev => [...prev, realtimeEvent]);
    });
    

    client.on('error', (event: any) => console.error(event));

    client.on('conversation.interrupted', async () => {
      const trackSampleOffset = await wavStreamPlayerRef.current.interrupt();
      if (trackSampleOffset?.trackId) {
        const { trackId, offset } = trackSampleOffset;
        await client.cancelResponse(trackId, offset);
      }
    });

    client.on('conversation.updated', async ({ item, delta }: { item: any, delta: any }) => {
        console.log('1/ item.status', item.status);
        if (delta?.audio) {
            wavStreamPlayerRef.current.add16BitPCM(delta.audio, item.id);
            setIsAIActuallySpeaking(true);
          } else {
            setIsAIActuallySpeaking(false);
          }
    //   console.log('waiting');
      if (item.status === 'completed' && item.formatted.audio?.length) {
        const wavFile = await WavRecorder.decode(item.formatted.audio, 24000, 24000);
        item.formatted.file = wavFile;
      }
      setItems(client.conversation.getItems());
    });

    return () => {
      client.disconnect();
    };
  }, []);

  // Set up audio visualization
  useEffect(() => {
    let isLoaded = true;

    const render = () => {
      if (!isLoaded) return;

      // Client audio visualization
      const clientCanvas = clientCanvasRef.current;
      if (clientCanvas) {
        const ctx = clientCanvas.getContext('2d');
        if (ctx) {
          const result = wavRecorderRef.current.recording
            ? wavRecorderRef.current.getFrequencies('voice')
            : { values: new Float32Array([0]) };
          WavRenderer.drawBars(clientCanvas, ctx, result.values, '#0099ff', 10, 0, 8);
        }
      }

      // Server audio visualization
      const serverCanvas = serverCanvasRef.current;
      if (serverCanvas) {
        const ctx = serverCanvas.getContext('2d');
        if (ctx) {
          const result = wavStreamPlayerRef.current.analyser
            ? wavStreamPlayerRef.current.getFrequencies('voice')
            : { values: new Float32Array([0]) };
          WavRenderer.drawBars(serverCanvas, ctx, result.values, '#009900', 10, 0, 8);
        }
      }

      requestAnimationFrame(render);
    };

    render();
    return () => { isLoaded = false; };
  }, []);

  // Handle recording starts
  const startRecording = async () => {
    try {
      setIsHolding(true);
      setIsAISpeaking(false);

      const client = clientRef.current;
      if (!client) return;

      if (!isConnected) {
        await wavRecorderRef.current.begin();
        await wavStreamPlayerRef.current.connect();
        await client.connect();
        client.sendUserMessageContent([
            { type: 'input_text', text: 'Hello!' }
        ]);
        setIsConnected(true);
      }

      const trackSampleOffset = await wavStreamPlayerRef.current.interrupt();
      if (trackSampleOffset?.trackId) {
        await client.cancelResponse(trackSampleOffset.trackId, trackSampleOffset.offset);
      }

      await wavRecorderRef.current.record((data) => client.appendInputAudio(data.mono));
    } catch (error) {
      console.error('Error starting recording:', error);
      setIsHolding(false);
    }
  };

  // Handle recording stops
  const stopRecording = async () => {
    try {
      setIsHolding(false);
      const client = clientRef.current;
      if (!client) return;

      await wavRecorderRef.current.pause();
      client.createResponse();
    } catch (error) {
      console.error('Error stopping recording:', error);
    }
  };

  useEffect(() => {
    const checkAudioPlaying = () => {
      const wavStreamPlayer = wavStreamPlayerRef.current;
      
      try {
        const frequencies = wavStreamPlayer.getFrequencies('voice');
        // Check if there's any significant audio signal
        const isPlaying = frequencies.values.some(value => value > 0.01);
        setIsAIActuallySpeaking(isPlaying);
      } catch (error) {
        // If not connected, assume not speaking
        setIsAIActuallySpeaking(false);
      }
    };
  
    // Only start checking if we're connected
    let interval: NodeJS.Timeout;
    if (isConnected) {
      interval = setInterval(checkAudioPlaying, 100);
    }
  
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isConnected]);

//   useEffect(() => {
//     let interval: NodeJS.Timeout;
//     if (isHolding || isAIActuallySpeaking) {  // Track time when either party is speaking
//       interval = setInterval(() => {
//         setTotalTime(prev => prev + 1);
//         // setCost(prev => Number(((totalTime + 1) * (COST_PER_MINUTE / 60)).toFixed(2)));
//       }, 1000);
//     }
//     return () => clearInterval(interval);
//   }, [isHolding, isAIActuallySpeaking, totalTime]);
  
  // Update this useEffect to only track user speaking time
  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isHolding || isAIActuallySpeaking) {  // Only track seconds when user is speaking
      interval = setInterval(() => {
        setSeconds(prev => prev + 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isHolding, isAIActuallySpeaking]);

  const formatTime = (secs: number) => {
    const mins = Math.floor(secs / 60);
    const remainingSecs = secs % 60;
    return `${mins}:${remainingSecs.toString().padStart(2, '0')}`;
  };

//   const metrics = calculateMetrics(seconds);
//   console.log('metrics', metrics);

  // Rest of your UI render code remains the same...
  return (
    <div data-component="VoiceReferenceConsole" className="fixed inset-0 bg-gray-900 flex flex-col select-none">
    {/* Header */}
  
    {/* <div className={`absolute top-2 right-2 h-2 w-2 rounded-full ${
      !isConnected ? 'bg-green-500' : 'bg-red-500'
    }`} /> */}
    
    <div className="bg-gray-800 p-4">
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-4">
          <div>
            <div className="text-sm text-gray-400 pointer-events-none">Reference for</div>
            <div className="text-white font-semibold pointer-events-none">Cam Burley</div>
          </div>
          
            
          {isAIActuallySpeaking ? (
            <div className="bg-blue-900/50 px-3 py-1.5 rounded-full flex items-center pointer-events-none">
              <div className="flex items-center space-x-1 mr-2">
                <div className="w-1 h-3 bg-blue-400 animate-bounce" />
                <div className="w-1 h-4 bg-blue-400 animate-bounce delay-100" />
                <div className="w-1 h-2 bg-blue-400 animate-bounce delay-200" />
              </div>
              <span className="text-sm text-blue-200">AI Speaking</span>
            </div>
          ) : (
            !isHolding && (
              <div className="bg-blue-900/50 px-3 py-1.5 rounded-full flex items-center pointer-events-none">
                <div className="w-2 h-2 bg-yellow-400 rounded-full mr-2" />
                <span className="text-sm text-blue-200">AI Waiting</span>
              </div>
            )
          )}
        </div>

        <div className="text-right text-white pointer-events-none">
        <div className="text-2xl font-mono font-bold">{formatTime(seconds)}</div>
            <div className="text-sm text-gray-400">Average time: 8 minutes</div>
            {showCosts && (
            <>
                <div className="text-sm text-gray-400">Revenue: ${metrics.revenue} | Cost: ${metrics.cost}</div>
                <div className="text-sm text-gray-400">Profit: ${metrics.profit} | Margin: {metrics.margin}%</div>
            </>
            )}
        </div>


      </div>

      {/* Subtle Progress Indicators */}
      <div className="mt-3 flex justify-center space-x-1">
        {topics.map((topic) => (
          <div key={topic.id} className="flex flex-col items-center pointer-events-none">
            <div 
              className={`w-8 h-1 rounded-full ${
                topic.status === 'completed' ? 'bg-green-500/50' :
                topic.status === 'active' ? 'bg-blue-500/50 animate-pulse' :
                'bg-gray-700'
              }`}
            />
            <div className="text-[10px] text-gray-500 mt-1">{topic.topic}</div>
          </div>
        ))}
      </div>
    </div>

    {/* Tips Panel */}
    {showTips && (
      <div className="bg-blue-900/20 backdrop-blur-sm">
        <div className="max-w-2xl mx-auto p-4">
          <div className="flex justify-between items-center mb-2">
            <div className="flex items-center text-white pointer-events-none">
              <Star className="w-4 h-4 text-blue-400 mr-2" />
              <span className="text-sm font-medium">Tips for a Great Reference</span>
            </div>
            <button 
              onClick={() => setShowTips(false)}
              className="text-sm text-blue-300 hover:text-white select-none"
            >
              Hide
            </button>
          </div>
          <div className="grid grid-cols-2 gap-x-8 gap-y-2 text-sm pointer-events-none">
            <div className="text-gray-300">• Speak naturally and conversationally</div>
            <div className="text-gray-300">• Take your time, no rush</div>
            <div className="text-gray-300">• Share specific examples</div>
            <div className="text-gray-300">• Include strengths and growth areas</div>
          </div>
        </div>
      </div>
    )}

    {/* Push to Talk Button */}
    <div className="flex-1 relative">
      <button
        onMouseDown={() => {
          setIsHolding(true);
          setIsAISpeaking(false);
          startRecording();
        }}
        
        onMouseUp={() => {
          setIsHolding(false);
          setIsAISpeaking(true);
          stopRecording();
        }}
        onMouseLeave={() => {
          setIsHolding(false);
          setIsAISpeaking(true);
        }}
        onTouchStart={() => {
          setIsHolding(true);
          setIsAISpeaking(false);
          startRecording();
        }}
        onTouchEnd={() => {
          setIsHolding(false);
          setIsAISpeaking(true);
          stopRecording();
        }}
        className={`absolute inset-0 transition-colors duration-200 flex items-center justify-center ${
          isHolding ? 'bg-red-600' : 'bg-blue-600'
        }`}
      >
        <div className={`transform transition-transform duration-200 ${
          isHolding ? 'scale-150' : 'scale-100'
        }`}>
          {isHolding ? (
            <div className="relative">
              <Mic className="w-32 h-32 text-white" />
              <div 
                className="absolute -top-16 left-1/2 transform -translate-x-1/2 bg-white/10 px-6 py-3 rounded-full text-white text-lg font-mono pointer-events-none"
                aria-label="Recording status"
              >
                Talking...
              </div>
            </div>
          ) : (
            <div className="relative">
              <MicOff className="w-32 h-32 text-white" />
              <div 
                className="absolute -top-16 left-1/2 transform -translate-x-1/2 text-white/80 text-lg w-48 text-center pointer-events-none"
                aria-label="Recording instructions"
              >
                Hold to Speak
              </div>
            </div>
          )}
        </div>
      </button>
    </div>
    {error && (
  <div className="absolute bottom-4 right-4 bg-red-500 text-white px-4 py-2 rounded">
    {error}
    <button onClick={() => setError(null)} className="ml-2">×</button>
      </div>
    )}

    {/* Footer */}
    <div className="bg-gray-800 p-4">
      <div className="flex items-center justify-center space-x-2 text-sm text-gray-400 pointer-events-none">
        <AlertCircle className="w-4 h-4" />
        <span>You can re-record any answer if needed</span>
      </div>
    </div>
  </div>
  );
};

export default VoiceReferenceConsole;
import React, { useState } from 'react';
import { Phone, Check, Clock, Star, Award } from 'lucide-react';

const CheckBox = () => {
  const [step, setStep] = useState(1);
  
  return (
    <div className="max-w-2xl mx-auto p-6 space-y-8">
      <div className="bg-white rounded-lg shadow-lg p-6">
        {/* <h2 className="text-2xl font-bold mb-4">RS Reference Check</h2> */}
        
        <div className="space-y-6">
          {/* Progress Steps */}
          <div className="flex justify-between">
            <div className={`flex items-center ${step >= 1 ? 'text-blue-600' : 'text-gray-400'}`}>
              <Phone className="w-6 h-6 mr-2" />
              <span>Manager Calls</span>
            </div>
            <div className={`flex items-center ${step >= 2 ? 'text-blue-600' : 'text-gray-400'}`}>
              <Check className="w-6 h-6 mr-2" />
              <span>Agent Interview</span>
            </div>
            <div className={`flex items-center ${step >= 3 ? 'text-blue-600' : 'text-gray-400'}`}>
              <Star className="w-6 h-6 mr-2" />
              <span>Verification</span>
            </div>
          </div>
          
          {/* Demo Controls */}
          <div className="flex justify-center space-x-4">
            <button 
              onClick={() => setStep(prev => Math.max(1, prev - 1))}
              className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
            >
              Previous
            </button>
            <button 
              onClick={() => setStep(prev => Math.min(3, prev + 1))}
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
            >
              Next Step
            </button>
          </div>
          
          {/* Step Content */}
          <div className="border rounded-lg p-4">
            {step === 1 && (
              <div className="space-y-4">
                <h3 className="font-bold">Step 1: Manager Reference Call</h3>
                <p>Manager calls dedicated number at their convenience</p>
                <div className="flex items-center text-green-600">
                  <Clock className="w-4 h-4 mr-2" />
                  <span>Average call time: 8 minutes</span>
                </div>
              </div>
            )}
            
            {step === 2 && (
              <div className="space-y-4">
                <h3 className="font-bold">Step 2: AI Interview</h3>
                <p>AI conducts structured interview and records key points</p>
                <div className="flex items-center text-blue-600">
                  <Award className="w-4 h-4 mr-2" />
                  <span>Consistent, unbiased questions</span>
                </div>
              </div>
            )}
            
            {step === 3 && (
              <div className="space-y-4">
                <h3 className="font-bold">Step 3: Verification Complete</h3>
                <p>Reference verified and ready for future employers</p>
                <div className="flex items-center text-purple-600">
                  <Star className="w-4 h-4 mr-2" />
                  <span>Professional profile enhanced</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckBox;

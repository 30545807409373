import { ConsolePage } from './pages/ConsolePage';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import './App.scss';
import './index.css'; 
import { OAuthCallback } from './pages/OAuthCallback';
import VoiceReferenceConsole from './pages/VoiceReferenceConsole';
import LandingPage from './pages/LandingPage';
import EmailLander from './pages/EmailLander';
import ReferenceCheck from './pages/ReferenceCheck';
import CallExperience from './pages/CallExperience';
import GetPaid from './pages/GetPaid';
import CheckBox from './pages/CheckBox';
import Invite from './pages/Invite';
import RefDash from './pages/RefDash';
import PayNeed from './pages/PayNeed';
import Profile from './pages/Profile';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ReferenceCheck />} />
        <Route path="/reference" element={<VoiceReferenceConsole />} />
        <Route path="/landing" element={<LandingPage />} />
        <Route path="/email" element={<EmailLander />} />
        <Route path="/ref" element={<ReferenceCheck />} />
        <Route path="/call" element={<CallExperience />} />
        <Route path="/paid" element={<GetPaid />} />
        <Route path="/checkbox" element={<CheckBox />} />
        <Route path="/invite" element={<Invite />} />
        <Route path="/refdash" element={<RefDash />} />
        <Route path="/pay" element={<PayNeed />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/oauth/callback" element={<OAuthCallback />} />
        <Route path="/auth/gmail/callback" element={<OAuthCallback />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

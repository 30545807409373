import React, { useState, useEffect } from 'react';
import { DollarSign, Star, Shield, Trophy, Briefcase, ArrowRight } from 'lucide-react';

const GetPaid = () => {
  const [activeStep, setActiveStep] = useState(0);
  
  const steps = [
    {
      icon: <Star className="w-12 h-12 text-yellow-500" />,
      title: "Your Work, Your Story",
      description: "Let your references speak for you",
      stat: "$25K+ higher offers",
      detail: "when rates are verified"
    },
    {
      icon: <DollarSign className="w-12 h-12 text-green-500" />,
      title: "Brand Your Value",
      description: "Show verified past rates",
      stat: "89% faster closes",
      detail: "with rate verification"
    },
    {
      icon: <Shield className="w-12 h-12 text-blue-500" />,
      title: "One Source of Truth",
      description: "Background checks & references in one place",
      stat: "4.8x more responses",
      detail: "from potential clients"
    }
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setActiveStep((prev) => (prev + 1) % steps.length);
    }, 4000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="max-w-5xl mx-auto p-6">
      {/* Hero Section */}
      <div className="text-center mb-16">
        <h1 className="text-5xl font-bold mb-6">Stand Out. Get Paid What You're Worth.</h1>
        <p className="text-xl text-gray-600 mb-8">Your professional record, verified and working for you 24/7</p>
        <button className="bg-blue-600 text-white px-8 py-4 rounded-lg text-lg font-semibold hover:bg-blue-700 transition-colors">
          Build Your Profile
        </button>
      </div>

      {/* Social Proof */}
      <div className="flex justify-center space-x-8 mb-16">
        <div className="text-center">
          <div className="text-3xl font-bold text-blue-600">$150K+</div>
          <div className="text-gray-600">Average Rate Increase</div>
        </div>
        <div className="text-center">
          <div className="text-3xl font-bold text-blue-600">92%</div>
          <div className="text-gray-600">Faster Hiring</div>
        </div>
        <div className="text-center">
          <div className="text-3xl font-bold text-blue-600">10K+</div>
          <div className="text-gray-600">Verified Professionals</div>
        </div>
      </div>

      {/* Interactive Demo */}
      <div className="bg-white rounded-xl shadow-2xl p-8 mb-16">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          {/* Visual Side */}
          <div className="relative h-96 bg-gray-50 rounded-lg p-6">
            {steps.map((step, index) => (
              <div
                key={index}
                className={`absolute inset-0 flex flex-col items-center justify-center transition-opacity duration-500 ${
                  activeStep === index ? 'opacity-100' : 'opacity-0'
                }`}
              >
                <div className="mb-4">{step.icon}</div>
                <div className="text-3xl font-bold mb-2">{step.stat}</div>
                <div className="text-gray-600">{step.detail}</div>
              </div>
            ))}
          </div>

          {/* Steps Side */}
          <div className="space-y-8">
            {steps.map((step, index) => (
              <div
                key={index}
                className={`transition-all duration-500 ${
                  activeStep === index 
                    ? 'transform translate-x-0 opacity-100'
                    : 'transform translate-x-4 opacity-50'
                }`}
                onClick={() => setActiveStep(index)}
              >
                <div className="flex items-start space-x-4 cursor-pointer">
                  <div className="mt-1">{step.icon}</div>
                  <div>
                    <h3 className="text-xl font-bold mb-2">{step.title}</h3>
                    <p className="text-gray-600">{step.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Features Grid */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
        <div className="bg-white p-6 rounded-lg shadow">
          <Briefcase className="w-8 h-8 text-purple-600 mb-4" />
          <h3 className="text-lg font-semibold mb-2">Verified Work History</h3>
          <p className="text-gray-600">Every project, every role, all verified</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow">
          <Trophy className="w-8 h-8 text-orange-600 mb-4" />
          <h3 className="text-lg font-semibold mb-2">Rate History</h3>
          <p className="text-gray-600">Show your value progression</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow">
          <Star className="w-8 h-8 text-yellow-600 mb-4" />
          <h3 className="text-lg font-semibold mb-2">Reference Library</h3>
          <p className="text-gray-600">Real voices backing your work</p>
        </div>
      </div>

      {/* Call to Action */}
      <div className="text-center bg-blue-50 p-12 rounded-xl">
        <h2 className="text-3xl font-bold mb-4">Ready to Level Up?</h2>
        <p className="text-xl text-gray-600 mb-8">Join thousands of professionals who've increased their rates by 40%</p>
        <button className="bg-blue-600 text-white px-8 py-4 rounded-lg text-lg font-semibold hover:bg-blue-700 transition-colors flex items-center mx-auto">
          Get Started <ArrowRight className="ml-2 w-5 h-5" />
        </button>
      </div>
    </div>
  );
};

export default GetPaid;

import React, { useState } from 'react';
import { Phone, Shield, Clock, ArrowRight, Check } from 'lucide-react';

const CallExperience = () => {
  const [currentStep, setCurrentStep] = useState(0);
  
  const steps = [
    {
      title: "1. Initial Call",
      description: "Sandy clicks link to call ReferenceSpeak AI",
      aiMessage: "Welcome to ReferenceSpeak! Please enter your verification code.",
      userAction: "Enters: REF-12345",
      time: "10 seconds"
    },
    {
      title: "2. Identity Verification",
      description: "Confirm identity and relationship",
      aiMessage: "Thanks Sandy. I see you're providing a reference for Cam Burley. You were Cam's supervisor at TechCorp from 2020-2023. Is this correct?",
      userAction: "Responds: 'Yes, that's correct'",
      time: "15 seconds"
    },
    {
      title: "3. Work Experience",
      description: "Share details about working together",
      aiMessage: "Great. Could you tell me about Cam's main responsibilities at TechCorp?",
      userAction: "Describes Cam's role and projects",
      time: "3 minutes"
    },
    {
      title: "4. Performance Review",
      description: "Discuss strengths and growth areas",
      aiMessage: "What were Cam's key strengths and areas of growth in this role?",
      userAction: "Shares feedback about performance",
      time: "3 minutes"
    },
    {
      title: "5. Review & Confirm",
      description: "Verify key points captured",
      aiMessage: "I've summarized the key points from your reference. Let me read them back to you...",
      userAction: "Confirms accuracy or makes corrections",
      time: "1 minute"
    }
  ];

  return (
    <div className="max-w-4xl mx-auto p-6">
      <div className="bg-white rounded-lg shadow-lg overflow-hidden">
        {/* Header */}
        <div className="bg-blue-600 text-white p-6">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-2xl font-bold">Reference Call Experience</h2>
            <div className="flex items-center space-x-2">
              <Clock className="w-5 h-5" />
              <span>Average call: 8 minutes</span>
            </div>
          </div>
          <p>When Sandy calls to leave a reference for Cam, here's what happens:</p>
        </div>

        {/* Timeline */}
        <div className="p-6">
          <div className="space-y-8">
            {steps.map((step, index) => (
              <div 
                key={index}
                className={`flex gap-6 ${
                  currentStep === index ? 'opacity-100' : 'opacity-60'
                }`}
                onClick={() => setCurrentStep(index)}
              >
                {/* Timeline Line */}
                <div className="flex flex-col items-center">
                  <div className={`w-8 h-8 rounded-full flex items-center justify-center ${
                    currentStep === index ? 'bg-blue-600 text-white' : 'bg-gray-200'
                  }`}>
                    {index + 1}
                  </div>
                  {index < steps.length - 1 && (
                    <div className="w-0.5 h-full bg-gray-200 mt-2" />
                  )}
                </div>

                {/* Content */}
                <div className="flex-1 pb-8">
                  <div className="flex justify-between items-start mb-2">
                    <h3 className="font-semibold text-lg">{step.title}</h3>
                    <span className="text-sm text-gray-500">{step.time}</span>
                  </div>
                  <p className="text-gray-600 mb-4">{step.description}</p>
                  
                  {/* Conversation Example */}
                  <div className="bg-gray-50 rounded-lg p-4 space-y-3">
                    <div className="flex items-start space-x-3">
                      <div className="w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center mt-1">
                        <Phone className="w-4 h-4 text-blue-600" />
                      </div>
                      <div className="flex-1">
                        <div className="text-sm text-gray-500">AI Assistant</div>
                        <div className="bg-white p-3 rounded shadow-sm">
                          {step.aiMessage}
                        </div>
                      </div>
                    </div>
                    <div className="flex items-start space-x-3">
                      <div className="w-8 h-8 bg-green-100 rounded-full flex items-center justify-center mt-1">
                        <Shield className="w-4 h-4 text-green-600" />
                      </div>
                      <div className="flex-1">
                        <div className="text-sm text-gray-500">Sandy's Response</div>
                        <div className="bg-green-50 p-3 rounded">
                          {step.userAction}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Footer */}
        <div className="border-t p-4 bg-gray-50">
          <div className="flex items-center justify-between">
            <div className="text-sm text-gray-600">
              After the call, Sandy receives an email recapping the reference.
            </div>
            {/* <button className="flex items-center space-x-2 text-blue-600">
              <span>See what happens next</span>
              <ArrowRight className="w-4 h-4" />
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallExperience;

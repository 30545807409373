import React, { useState } from 'react';
import { File, Upload, Edit2, Trash2, Clock, CheckCircle, AlertCircle, Download } from 'lucide-react';

const RefDash = () => {
  const [showResumeUpdate, setShowResumeUpdate] = useState(false);
  const [currentResume, setCurrentResume] = useState({
    filename: "Cam_Burley_Resume_2024.pdf",
    lastUpdated: "Mar 15, 2024",
    size: "1.2 MB"
  });

  const parsedPositions = [
    {
      company: "TechCorp",
      title: "Senior Developer",
      period: "2020-2023",
      supervisor: "Sandy Woods",
      supervisorEmail: "s.woods@techcorp.com",
      referenceStatus: "complete"
    },
    {
      company: "StartupX",
      title: "Backend Developer",
      period: "2018-2020",
      supervisor: "James Martinez",
      supervisorEmail: "j.martinez@startupx.com",
      referenceStatus: "pending"
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50 p-6">
      <div className="max-w-4xl mx-auto space-y-6">
        {/* Resume Section */}
        <div className="bg-white rounded-lg shadow-lg p-6">
          <div className="flex justify-between items-start mb-6">
            <h2 className="text-xl font-semibold">Resume Management</h2>
            <button 
              onClick={() => setShowResumeUpdate(true)}
              className="text-blue-600 hover:text-blue-700"
            >
              Update Resume
            </button>
          </div>

          {/* Current Resume */}
          {!showResumeUpdate ? (
            <div className="border rounded-lg p-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-4">
                  <File className="w-8 h-8 text-blue-600" />
                  <div>
                    <div className="font-medium">{currentResume.filename}</div>
                    <div className="text-sm text-gray-500">
                      Last updated: {currentResume.lastUpdated} • {currentResume.size}
                    </div>
                  </div>
                </div>
                <div className="flex space-x-2">
                  <button className="p-2 text-gray-500 hover:text-gray-700">
                    <Download className="w-5 h-5" />
                  </button>
                  <button className="p-2 text-gray-500 hover:text-gray-700">
                    <Edit2 className="w-5 h-5" />
                  </button>
                  <button className="p-2 text-red-500 hover:text-red-700">
                    <Trash2 className="w-5 h-5" />
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="border-2 border-dashed border-gray-300 rounded-lg p-8">
              <div className="text-center">
                <Upload className="w-12 h-12 text-gray-400 mx-auto mb-4" />
                <p className="text-gray-600 mb-4">
                  Drag and drop new resume or click to browse
                </p>
                <button className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700">
                  Select File
                </button>
                <button 
                  onClick={() => setShowResumeUpdate(false)}
                  className="ml-4 text-gray-600 hover:text-gray-800"
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
        </div>

        {/* Parsed Positions */}
        <div className="bg-white rounded-lg shadow-lg p-6">
          <h2 className="text-xl font-semibold mb-6">Work Experience</h2>
          <div className="space-y-4">
            {parsedPositions.map((position, index) => (
              <div key={index} className="border rounded-lg p-4">
                <div className="flex items-start justify-between mb-4">
                  <div>
                    <h3 className="font-medium">{position.company}</h3>
                    <p className="text-gray-600">{position.title}</p>
                    <p className="text-sm text-gray-500">{position.period}</p>
                  </div>
                  <div>
                    {position.referenceStatus === 'complete' ? (
                      <span className="bg-green-100 text-green-600 px-3 py-1 rounded-full text-sm flex items-center">
                        <CheckCircle className="w-4 h-4 mr-1" />
                        Reference Complete
                      </span>
                    ) : (
                      <span className="bg-yellow-100 text-yellow-600 px-3 py-1 rounded-full text-sm flex items-center">
                        <Clock className="w-4 h-4 mr-1" />
                        Reference Pending
                      </span>
                    )}
                  </div>
                </div>
                <div className="bg-gray-50 p-3 rounded">
                  <div className="text-sm">
                    <div className="font-medium">Supervisor Details</div>
                    <div>{position.supervisor}</div>
                    <div className="text-gray-600">{position.supervisorEmail}</div>
                  </div>
                  <div className="mt-3 flex justify-end space-x-2">
                    <button className="text-blue-600 text-sm hover:underline">
                      Edit Details
                    </button>
                    <button className="text-blue-600 text-sm hover:underline">
                      View Reference
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Help Section */}
        <div className="bg-blue-50 rounded-lg p-4 text-sm text-blue-800">
          <h3 className="font-medium mb-2">Need to make changes?</h3>
          <p>
            Upload a new resume anytime to update your work history. Your existing references will remain intact.
          </p>
        </div>
      </div>
    </div>
  );
};

export default RefDash;

import React, { useState } from 'react';
import { Check, Star, Shield, Zap, Users } from 'lucide-react';

const PayNeed = () => {
  const [showCalculator, setShowCalculator] = useState(false);
  const [referenceCount, setReferenceCount] = useState(3);
  
  const calculatePrice = (count: any) => {
    if (count >= 10) return 19;
    if (count >= 5) return 24;
    return 29;
  };

  return (
    <div className="max-w-6xl mx-auto p-6">
      {/* Header */}
      <div className="text-center mb-12">
        <div className="bg-green-100 text-green-800 px-4 py-2 rounded-full inline-block mb-6">
          Launch Special: References start at $19 each
        </div>
        <h2 className="text-3xl font-bold mb-4">Pay Only For What You Need</h2>
        <p className="text-xl text-gray-600 mb-6">No subscriptions. No commitments.</p>
      </div>

      {/* Price Calculator */}
      <div className="max-w-xl mx-auto mb-16 bg-white rounded-lg shadow-lg p-6">
        <h3 className="text-xl font-semibold mb-6">Calculate Your Price</h3>
        
        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium mb-2">
              How many references do you need?
            </label>
            <input 
              type="range" 
              min="1" 
              max="10" 
              value={referenceCount}
              onChange={(e) => setReferenceCount(parseInt(e.target.value))}
              className="w-full"
            />
            <div className="flex justify-between mt-2">
              <span className="text-sm text-gray-600">1</span>
              <span className="text-sm text-gray-600">5</span>
              <span className="text-sm text-gray-600">10+</span>
            </div>
          </div>

          <div className="bg-blue-50 p-6 rounded-lg">
            <div className="text-center">
              <div className="text-sm text-gray-600 mb-2">Your Price Per Reference:</div>
              <div className="text-4xl font-bold text-blue-600">
                ${calculatePrice(referenceCount)}
              </div>
              <div className="text-sm text-gray-600 mt-2">
                Total: ${calculatePrice(referenceCount) * referenceCount}
              </div>
            </div>
          </div>

          <div className="space-y-3">
            <div className="flex items-center space-x-3">
              <Check className="w-5 h-5 text-green-600" />
              <span>AI-powered reference calls</span>
            </div>
            <div className="flex items-center space-x-3">
              <Check className="w-5 h-5 text-green-600" />
              <span>Professional profile page</span>
            </div>
            <div className="flex items-center space-x-3">
              <Check className="w-5 h-5 text-green-600" />
              <span>Valid for 90 days</span>
            </div>
          </div>

          <button className="w-full bg-blue-600 text-white py-3 rounded-lg hover:bg-blue-700">
            Get Started
          </button>
        </div>
      </div>

      {/* Value Props */}
      <div className="grid md:grid-cols-3 gap-8 mb-16">
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <Star className="w-8 h-8 text-yellow-500 mb-4" />
          <h3 className="font-semibold mb-2">Bulk Savings</h3>
          <p className="text-gray-600">Price drops to $19/reference when you buy 10 or more</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <Shield className="w-8 h-8 text-blue-500 mb-4" />
          <h3 className="font-semibold mb-2">Money Back Guarantee</h3>
          <p className="text-gray-600">Not satisfied? Get a full refund within 30 days</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <Users className="w-8 h-8 text-green-500 mb-4" />
          <h3 className="font-semibold mb-2">Team Accounts</h3>
          <p className="text-gray-600">Special pricing for recruiting teams. Contact sales</p>
        </div>
      </div>

      {/* FAQ */}
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h3 className="text-xl font-semibold mb-6">Common Questions</h3>
        <div className="grid md:grid-cols-2 gap-6">
          <div>
            <h4 className="font-medium mb-2">How long are references valid?</h4>
            <p className="text-gray-600">References are valid for 90 days after purchase. Share them unlimited times during this period.</p>
          </div>
          <div>
            <h4 className="font-medium mb-2">What if my reference doesn't respond?</h4>
            <p className="text-gray-600">No charge if your reference doesn't complete the call. Only pay for successful references.</p>
          </div>
          <div>
            <h4 className="font-medium mb-2">Can I buy more later?</h4>
            <p className="text-gray-600">Yes! Buy exactly what you need, when you need it. Bulk discounts always apply.</p>
          </div>
          <div>
            <h4 className="font-medium mb-2">Do you offer refunds?</h4>
            <p className="text-gray-600">100% money-back guarantee if you're not satisfied within 30 days.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayNeed;

import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export function OAuthCallback() {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const handleCallback = async () => {
            const params = new URLSearchParams(location.search);
            const code = params.get('code');
            const state = params.get('state');

            console.log('OAuthCallback received:', { code, state });

            if (!code) {
                console.error('No authorization code received');
                navigate('/?error=no_code');
                return;
            }

            try {
                const response = await fetch(
                    `http://localhost:5559/auth/gmail/callback?code=${code}&state=${state || ''}`,
                    {
                        headers: {
                            'Accept': 'application/json'
                        }
                    }
                );

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                console.log('Received auth data:', data);

                if (data.userId) {
                    localStorage.setItem('gmail_user_id', data.userId);
                    const storedId = localStorage.getItem('gmail_user_id');
                    console.log('Stored userId in localStorage:', storedId);

                    if (data.email) {
                        localStorage.setItem('gmail_user_email', data.email);
                        console.log('Stored email in localStorage:', data.email);
                    }

                    const verifyResponse = await fetch(`http://localhost:5559/auth/gmail/status?userId=${data.userId}`);
                    const verifyData = await verifyResponse.json();
                    console.log('Verified auth status:', verifyData);

                    if (verifyData.isAuthenticated) {
                        navigate('/', { replace: true });
                    } else {
                        throw new Error('Failed to verify authentication');
                    }
                } else {
                    throw new Error('No userId received from server');
                }
            } catch (error) {
                console.error('OAuth callback error:', error);
                localStorage.removeItem('gmail_user_id');
                localStorage.removeItem('gmail_user_email');
                navigate('/?error=auth_failed&details=' + encodeURIComponent((error as Error).message), { replace: true });
            }
        };

        handleCallback();
    }, [navigate, location]);

    return (
        <div style={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            height: '100vh',
            flexDirection: 'column',
            gap: '20px'
        }}>
            <h2>Authenticating with Gmail...</h2>
            <div>Please wait while we complete the authentication process.</div>
            <div className="loading-spinner"></div>
        </div>
    );
} 
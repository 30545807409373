import React, { useState } from 'react';
import { Play, Calendar, Building, Clock, Star, Download, Shield, ArrowRight, Volume2, Briefcase, GraduationCap } from 'lucide-react';

const Profile = () => {
  const [activeReference, setActiveReference] = useState(null);

  const references = [
    {
      id: 1,
      type: 'project',
      referrer: 'Sandy Woods',
      referrerRole: 'Project Manager',
      company: 'TechCorp',
      period: 'Jan 2022 - Dec 2023',
      project: 'Enterprise API Platform',
      role: 'Senior Backend Developer',
      rate: '$150/hr',
      verified: true,
      keyQuotes: [
        "Led the backend architecture for our $2M platform upgrade",
        "Exceptional problem-solver who consistently met tight deadlines",
        "Excellent at mentoring junior developers"
      ],
      audioClips: [
        { title: 'Technical Leadership', duration: '45s' },
        { title: 'Project Impact', duration: '35s' },
        { title: 'Team Collaboration', duration: '28s' }
      ]
    },
    {
      id: 2,
      type: 'project',
      referrer: 'James Martinez',
      referrerRole: 'Technical Director',
      company: 'InnovateCo',
      period: 'Mar 2021 - Nov 2021',
      project: 'Payment Gateway Integration',
      role: 'Lead Developer',
      rate: '$135/hr',
      verified: true,
      keyQuotes: [
        "Delivered the payment system 2 weeks ahead of schedule",
        "Great communication with both technical and business teams",
        "Thorough documentation and knowledge transfer"
      ],
      audioClips: [
        { title: 'Project Delivery', duration: '40s' },
        { title: 'Communication Skills', duration: '32s' }
      ]
    },
    {
      id: 3,
      type: 'project',
      referrer: 'Lisa Chen',
      referrerRole: 'Product Owner',
      company: 'StartupX',
      period: 'Jun 2020 - Feb 2021',
      project: 'Mobile App Launch',
      role: 'Backend Developer',
      rate: '$125/hr',
      verified: true,
      keyQuotes: [
        "Built our entire backend infrastructure from scratch",
        "Crucial in helping us meet our launch deadline",
        "Very proactive in suggesting performance improvements"
      ],
      audioClips: [
        { title: 'Technical Impact', duration: '38s' },
        { title: 'Initiative', duration: '29s' }
      ]
    },
    {
      id: 4,
      type: 'education',
      referrer: 'Michael Brown',
      referrerRole: 'Student',
      company: 'Digital Architecture Course',
      period: 'Sep 2023',
      project: 'Advanced System Design Course',
      verified: true,
      keyQuotes: [
        "Excellent instructor who explains complex concepts clearly",
        "Provided practical, real-world examples",
        "Very responsive to questions and provided detailed feedback"
      ],
      audioClips: [
        { title: 'Teaching Style', duration: '42s' },
        { title: 'Course Value', duration: '31s' }
      ]
    }
  ];

  return (
    <div className="max-w-5xl mx-auto p-6">
      {/* Profile Header */}
      <div className="bg-white rounded-lg shadow-lg p-6 mb-8">
        <div className="flex justify-between items-start">
          <div>
            <h1 className="text-2xl font-bold mb-2">Cam Burley</h1>
            <p className="text-gray-600 mb-4">Senior Backend Developer</p>
            <div className="flex items-center space-x-4 text-sm">
              <div className="flex items-center">
                <Shield className="w-4 h-4 text-green-600 mr-1" />
                <span>All References Verified</span>
              </div>
              <div className="flex items-center">
                <Clock className="w-4 h-4 text-blue-600 mr-1" />
                <span>Last Updated: Mar 15, 2024</span>
              </div>
            </div>
          </div>
          <button className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700">
            Download Full Report
          </button>
        </div>
      </div>

      {/* Reference Stats */}
      <div className="grid grid-cols-4 gap-4 mb-8">
        <div className="bg-white p-4 rounded-lg shadow">
          <div className="text-gray-600 text-sm mb-1">Average Rate</div>
          <div className="text-2xl font-bold">$137/hr</div>
          <div className="text-green-600 text-sm">Verified</div>
        </div>
        <div className="bg-white p-4 rounded-lg shadow">
          <div className="text-gray-600 text-sm mb-1">Total Experience</div>
          <div className="text-2xl font-bold">3.5 years</div>
          <div className="text-blue-600 text-sm">4 references</div>
        </div>
        <div className="bg-white p-4 rounded-lg shadow">
          <div className="text-gray-600 text-sm mb-1">Reference Score</div>
          <div className="text-2xl font-bold">4.9/5.0</div>
          <div className="text-yellow-600 text-sm">Top 5%</div>
        </div>
        <div className="bg-white p-4 rounded-lg shadow">
          <div className="text-gray-600 text-sm mb-1">Background Check</div>
          <div className="text-2xl font-bold">Clear</div>
          <div className="text-green-600 text-sm">Mar 2024</div>
        </div>
      </div>

      {/* References List */}
      <div className="space-y-6">
        {references.map((ref) => (
          <div key={ref.id} className="bg-white rounded-lg shadow-lg overflow-hidden">
            <div className="p-6">
              <div className="flex justify-between items-start mb-4">
                <div>
                  <div className="flex items-center space-x-2 mb-2">
                    {ref.type === 'project' ? (
                      <Briefcase className="w-5 h-5 text-blue-600" />
                    ) : (
                      <GraduationCap className="w-5 h-5 text-purple-600" />
                    )}
                    <h3 className="text-xl font-semibold">{ref.company}</h3>
                  </div>
                  <p className="text-gray-600">{ref.project}</p>
                </div>
                <div className="text-right">
                  <div className="text-sm text-gray-600">{ref.period}</div>
                  {ref.rate && (
                    <div className="text-green-600 font-semibold">{ref.rate}</div>
                  )}
                </div>
              </div>

              <div className="border-t border-b py-4 my-4">
                <div className="flex items-center space-x-4 mb-4">
                  <div>
                    <div className="font-medium">{ref.referrer}</div>
                    <div className="text-sm text-gray-600">{ref.role}</div>
                  </div>
                  {ref.verified && (
                    <div className="bg-green-100 text-green-600 text-sm px-2 py-1 rounded">
                      Verified Reference
                    </div>
                  )}
                </div>

                <div className="space-y-2">
                  {ref.keyQuotes.map((quote, index) => (
                    <div key={index} className="flex items-start space-x-2">
                      <Star className="w-4 h-4 text-yellow-400 mt-1" />
                      <p className="text-gray-700">"{quote}"</p>
                    </div>
                  ))}
                </div>
              </div>

              <div className="flex flex-wrap gap-2">
                {ref.audioClips.map((clip, index) => (
                  <button 
                    key={index}
                    className="flex items-center space-x-2 bg-gray-100 hover:bg-gray-200 px-3 py-2 rounded"
                  >
                    <Volume2 className="w-4 h-4 text-blue-600" />
                    <span>{clip.title}</span>
                    <span className="text-gray-500 text-sm">({clip.duration})</span>
                  </button>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Call to Action */}
      <div className="bg-blue-50 rounded-lg p-6 mt-8 flex justify-between items-center">
        <div>
          <h3 className="font-semibold mb-1">Need more information?</h3>
          <p className="text-gray-600">Schedule a call to discuss Cam's experience in detail.</p>
        </div>
        <button className="flex items-center space-x-2 bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700">
          <span>Schedule Call</span>
          <ArrowRight className="w-4 h-4" />
        </button>
      </div>
    </div>
  );
};

export default Profile;

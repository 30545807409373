import React from 'react';
import { Mail, User, Play, Star, Clock, Check, X } from 'lucide-react';

const EmailLander = () => {
  return (
    <div className="max-w-5xl mx-auto px-4 py-8">
      {/* Hero Section */}
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold mb-4">Turn Your Track Record Into Opportunities</h1>
        <p className="text-xl mb-6">Stand out with verified voice references</p>
      </div>

      {/* Email Comparison */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
        {/* Traditional Email */}
        <div className="p-6 mb-16 bg-blue-50">
          <div className="mb-4 text-gray-500 flex items-center gap-2">
            <X className="text-red-500" />
            <span>Traditional Outreach</span>
          </div>
          <div className="border rounded-lg overflow-hidden">
            {/* Email Header */}
            <div className="bg-gray-50 p-4 border-b">
              <div className="flex items-center gap-3">
                <Mail className="text-gray-400" />
                <div>
                  <p className="text-sm text-gray-600">To: hiring@dreamcompany.com</p>
                  <p className="text-sm text-gray-600">Subject: Senior Role Application</p>
                </div>
              </div>
            </div>
            
            {/* Email Content */}
            <div className="p-4 bg-white">
              <p className="text-gray-700 mb-4">Hi,</p>
              <p className="text-gray-700 mb-4">I'm interested in the Senior Role position. I have 5 years of experience and a proven track record.</p>
              <p className="text-gray-700 mb-4">Please find my resume attached.</p>
              <p className="text-gray-400">References available upon request.</p>
              
              <div className="mt-8 text-gray-400 text-sm">
                <div className="flex items-center gap-2 mb-2">
                  <Clock className="h-4 w-4" />
                  <span>Waiting for response...</span>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4 text-center text-gray-500">
            Just another email in their inbox
          </div>
          </div>

        {/* ReferenceSpeak Email */}
        <div className="p-6 mb-16 bg-blue-50">
          <div className="mb-4 text-blue-600 flex items-center gap-2">
            <Check className="text-green-500" />
            <span>With ReferenceSpeak</span>
          </div>
          <div className="border rounded-lg overflow-hidden">
            {/* Email Header */}
            <div className="bg-gray-50 p-4 border-b">
              <div className="flex items-center gap-3">
                <Mail className="text-blue-500" />
                <div>
                  <p className="text-sm text-gray-600">To: hiring@dreamcompany.com</p>
                  <p className="text-sm text-gray-600">Subject: Senior Role + Voice References</p>
                </div>
              </div>
            </div>
            
            {/* Email Content */}
            <div className="p-4 bg-white">
              <p className="text-gray-700 mb-4">Hi,</p>
              <p className="text-gray-700 mb-4">I'm interested in the Senior Role position. Instead of just telling you about my 5 years of experience, I'd like you to hear directly from the people I've worked with.</p>
              
              {/* Reference Previews */}
              <div className="space-y-3 mb-4">
                <div className="bg-blue-50 p-3 rounded-lg flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <User className="text-blue-500" />
                    <div>
                      <p className="text-sm font-medium">Previous Manager</p>
                      <p className="text-xs text-gray-600">Leadership & Impact</p>
                    </div>
                  </div>
                  <Play className="text-blue-500" />
                </div>
                
                <div className="bg-blue-50 p-3 rounded-lg flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <User className="text-blue-500" />
                    <div>
                      <p className="text-sm font-medium">Senior Client</p>
                      <p className="text-xs text-gray-600">Project Delivery</p>
                    </div>
                  </div>
                  <Play className="text-blue-500" />
                </div>
              </div>

              <p className="text-gray-700">Resume attached as well.</p>
              
              <div className="mt-8 text-green-600 text-sm">
                <div className="flex items-center gap-2 mb-2">
                  <Star className="h-4 w-4" />
                  <span>Interview scheduled for Thursday</span>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4 text-center text-green-600">
            Stand out instantly
          </div>
        </div>
      </div>

      {/* Simple Value Prop */}
      <div className="text-center mb-16">
        <div className="p-8 bg-blue-50 inline-block">
          <div className="text-3xl font-bold text-blue-600 mb-2">~$12</div>
          <p className="text-gray-600">per verified reference</p>
          <button className="bg-blue-600 hover:bg-blue-700 text-white px-8 py-4 rounded-lg text-lg">
            Start Standing Out
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmailLander;

import React, { useEffect, useState } from 'react';

import { Check, Play, Clock, Mail, User, X, Star, Users, Shield, ArrowRight, DollarSign, Trophy, Phone, Award, ExternalLink } from 'lucide-react';
import { Button } from '../components/button/Button';
import CallExperience from './CallExperience';
import Invite from './Invite';

const Modal = ({ isOpen, onClose, children }: { isOpen: boolean; onClose: () => void; children: React.ReactNode }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4">
        {/* Backdrop */}
        <div 
          className="fixed inset-0 bg-black/30 backdrop-blur-sm"
          onClick={onClose}
        ></div>
        
        {/* Modal content */}
        <div className="relative bg-white rounded-lg w-full max-w-4xl">
          <button
            onClick={onClose}
            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
          >
            <X className="w-6 h-6" />
          </button>
          {children}
        </div>
      </div>
    </div>
  );
};

const ReferenceCheck = () => {

    const [scrolled, setScrolled] = useState(false);
    const [minutes, setMinutes] = useState(8);
    const [step, setStep] = useState(1);
    const [selected, setSelected] = useState('');
    const RATE_PER_MINUTE = 1.50;
    const [isModalOpen, setIsModalOpen] = useState(false);
  
    const calculateTotal = (mins: any) => {
      return (mins * RATE_PER_MINUTE).toFixed(2);
    };

    useEffect(() => {
        const handleScroll = () => {
          setScrolled(window.scrollY > 10);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
      }, []);

    
      const Logo = () => (
        <svg width="40" height="40" viewBox="0 0 200 200">
          <path d="M100,0 l80,30 v60 a80,80 0 0 1 -80,80 a80,80 0 0 1 -80,-80 v-60 z" fill="#4A90E2"/>
          <path d="M60,90 h80 M60,70 h60 M60,110 h40" stroke="white" strokeWidth="8" strokeLinecap="round"/>
        </svg>
      );

      
  return (
    <>

     {/* Sticky Nav */}
     <div className={`fixed top-0 w-full z-50 transition-all duration-200 ${
        scrolled ? 'bg-white/80 backdrop-blur-md shadow-md' : 'bg-white'
      }`}>
        <div className="max-w-4xl mx-auto px-4 py-3 flex justify-between items-center">
          <div className="flex items-center gap-2">
            <Logo />
            <span className="font-bold text-xl text-blue-600">ReferenceSpeak</span>
          </div>
          <button className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded">
            Get Started
          </button>
        </div>
      </div>


    <div className="max-w-4xl mx-auto px-4 py-8 mt-20">
      {/* Hero Section */}
      
      <div className="text-center mb-12">
      <div className="bg-green-100 text-green-800 px-4 py-2 rounded-full inline-block mb-10">
        "Got interviewed next day after sharing voice references" - Sarah K.
      </div>
        <h1 className="text-4xl font-bold mb-4">Stand Out From Day One</h1>
        <p className="text-xl mb-6">Skip the phone tag. Share verified voice references instantly</p>
        <button className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded">
            Get Your First Reference → $12
        </button>
    
      </div>

      {/* <div className="text-center mb-12">
        <h1 className="text-4xl font-bold mb-4">Turn Reference Checks Into Opportunities</h1>
        <p className="text-xl mb-6">Your professional references, spoken authentically, shared effortlessly</p>
        <button className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded">
            Get Your References → $12
        </button>
    
      </div> */}

      {/* How It Works */}
      {/* <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
      <div className="bg-white p-6 rounded-lg shadow">
              <h3 className="text-xl font-bold mb-2">1. Share Link</h3>
              <p>Your references call at their convenience</p>
            </div>
        <div className="bg-white p-6 rounded-lg shadow">
          <h3 className="text-xl font-bold mb-2">2. AI Interview</h3>
          <p>Professional questions, real answers</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow">
          <h3 className="text-xl font-bold mb-2">3. Share Proof</h3>
          <p>Verified references that get attention</p>
        </div>
      </div> */}




      {/* The Journey */}
      <div className="mb-16">
        {/* <h3 className="text-2xl font-bold mb-8 text-center">Your Journey to Success</h3> */}
        <div className="relative">
          <div className="absolute top-1/2 left-0 right-0 h-1 bg-blue-200 -z-10 hidden md:block" />
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="p-6 bg-white">
              <div className="space-y-4">
                {/* <div className="bg-blue-100 w-12 h-12 rounded-full flex items-center justify-center mb-4">
                  <span className="text-xl font-bold">1</span>
                </div> */}
                <h4 className="font-bold">Easy Collection</h4>
                <div className="space-y-3">
                  <div className="flex items-center gap-2 text-sm">
                    <Phone className="text-blue-500 h-4 w-4" />
                    <button 
                      onClick={ () => {
                        setSelected('invite');
                        setIsModalOpen(true);
                      }}
                      className="text-blue-600 underline hover:text-blue-700 inline-flex items-center gap-1"
                    >
                      Send your reference a link
                      <ExternalLink className="h-3 w-3" />
                    </button>
                  </div>
                  <div className="flex items-center gap-2 text-sm">
                    <Clock className="text-blue-500 h-4 w-4" />
                    <span>They answer when convenient</span>
                  </div>
                  <div className="flex items-center gap-2 text-sm">
                    <Shield className="text-blue-500 h-4 w-4" />
                    <button 
                      onClick={() => {
                        setSelected('call');
                        setIsModalOpen(true);
                      }}
                      className="text-blue-600 underline hover:text-blue-700 inline-flex items-center gap-1"
                    >
                      Professional verification
                      <ExternalLink className="h-3 w-3" />
                    </button>
                    
                  </div>
                </div>
              </div>
            </div>

            <div className="p-6 bg-white">
              <div className="space-y-4">
                {/* <div className="bg-blue-100 w-12 h-12 rounded-full flex items-center justify-center mb-4">
                  <span className="text-xl font-bold">2</span>
                </div> */}
                <h4 className="font-bold">Your Reference Library</h4>
                <div className="space-y-3">
                  <div className="bg-gray-50 p-3 rounded text-sm">
                    <div className="flex items-center gap-2">
                      <Shield className="text-green-500 h-4 w-4" />
                      <span>Full Stack Dev Reference</span>
                    </div>
                  </div>
                  <div className="bg-gray-50 p-3 rounded text-sm">
                    <div className="flex items-center gap-2">
                      <Shield className="text-green-500 h-4 w-4" />
                      <span>Front End Dev Reference</span>
                    </div>
                  </div>
                  <div className="bg-gray-50 p-3 rounded text-sm">
                    <div className="flex items-center gap-2">
                      <Shield className="text-green-500 h-4 w-4" />
                      <span>AI Engineer Reference</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="p-6 bg-white">
              <div className="space-y-4">
                {/* <div className="bg-blue-100 w-12 h-12 rounded-full flex items-center justify-center mb-4">
                  <span className="text-xl font-bold">3</span>
                </div> */}
                <h4 className="font-bold">Open Doors</h4>
                <div className="space-y-3">
                  <div className="bg-green-50 p-3 rounded text-sm">
                    <p className="font-medium">"Applying for Dream Job"</p>
                    <p className="text-green-600">→ Interview scheduled</p>
                  </div>
                  <div className="bg-green-50 p-3 rounded text-sm">
                    <p className="font-medium">"Pitching New Client"</p>
                    <p className="text-green-600">→ Meeting booked</p>
                  </div>
                  <div className="bg-green-50 p-3 rounded text-sm">
                    <p className="font-medium">"Cold Outreach"</p>
                    <p className="text-green-600">→ Call scheduled</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Benefits */}
      <div className="bg-gray-50 p-8 rounded-lg mb-12">
        <h2 className="text-2xl font-bold mb-6">Why It Works</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {[
            "No phone tag or scheduling",
            "References answer at their convenience",
            "Professional AI interviewer",
            "Verified voice references",
            "Stand out from competition",
            "Share instantly with anyone"
          ].map((benefit, index) => (
            <div key={index} className="flex items-center gap-2">
              <Check className="text-green-500" />
              <span>{benefit}</span>
            </div>
          ))}
        </div>
      </div>

      {/* Email Comparison */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
        {/* Traditional Email */}
        <div className="p-6 mb-16 bg-blue-50">
          <div className="mb-4 text-gray-500 flex items-center gap-2">
            <X className="text-red-500" />
            <span>Traditional Outreach</span>
          </div>
          <div className="border rounded-lg overflow-hidden">
            {/* Email Header */}
            <div className="bg-gray-50 p-4 border-b">
              <div className="flex items-center gap-3">
                <Mail className="text-gray-400" />
                <div>
                  <p className="text-sm text-gray-600">To: hiring@dreamcompany.com</p>
                  <p className="text-sm text-gray-600">Subject: Senior Role Application</p>
                </div>
              </div>
            </div>
            
            {/* Email Content */}
            <div className="p-4 bg-white">
              <p className="text-gray-700 mb-4">Hi,</p>
              <p className="text-gray-700 mb-4">I'm interested in the Senior Role position. I have 5 years of experience and a proven track record.</p>
              <p className="text-gray-700 mb-4">Please find my resume attached.</p>
              <p className="text-gray-400">References available upon request.</p>
              
              <div className="mt-8 text-gray-400 text-sm">
                <div className="flex items-center gap-2 mb-2">
                  <Clock className="h-4 w-4" />
                  <span>Waiting for response...</span>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4 text-center text-gray-500">
            Just another email in their inbox
          </div>
          </div>

        {/* ReferenceSpeak Email */}
        <div className="p-6 mb-16 bg-blue-50">
          <div className="mb-4 text-blue-600 flex items-center gap-2">
            <Check className="text-green-500" />
            <span>With ReferenceSpeak</span>
          </div>
          <div className="border rounded-lg overflow-hidden">
            {/* Email Header */}
            <div className="bg-gray-50 p-4 border-b">
              <div className="flex items-center gap-3">
                <Mail className="text-blue-500" />
                <div>
                  <p className="text-sm text-gray-600">To: hiring@dreamcompany.com</p>
                  <p className="text-sm text-gray-600">Subject: Senior Role + Voice References</p>
                </div>
              </div>
            </div>
            
            {/* Email Content */}
            <div className="p-4 bg-white">
              <p className="text-gray-700 mb-4">Hi,</p>
              <p className="text-gray-700 mb-4">I'm interested in the Senior Role position. Instead of just telling you about my 5 years of experience, I'd like you to hear directly from the people I've worked with.</p>
              
              {/* Reference Previews */}
              <div className="space-y-3 mb-4">
                <div className="bg-blue-50 p-3 rounded-lg flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <User className="text-blue-500" />
                    <div>
                      <p className="text-sm font-medium">Previous Manager</p>
                      <p className="text-xs text-gray-600">Leadership & Impact</p>
                    </div>
                  </div>
                  <Play className="text-blue-500" />
                </div>
                
                <div className="bg-blue-50 p-3 rounded-lg flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <User className="text-blue-500" />
                    <div>
                      <p className="text-sm font-medium">Senior Client</p>
                      <p className="text-xs text-gray-600">Project Delivery</p>
                    </div>
                  </div>
                  <Play className="text-blue-500" />
                </div>
              </div>

              <p className="text-gray-700">Resume attached as well.</p>
              
              <div className="mt-8 text-green-600 text-sm">
                <div className="flex items-center gap-2 mb-2">
                  <Star className="h-4 w-4" />
                  <span>Interview scheduled for Thursday</span>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-4 text-center text-green-600">
            Stand out instantly
          </div>
        </div>
      </div>


      {/* Investment Value Calculator */}
     <div className="p-6 mb-16 bg-blue-50">
        <div className="text-center mb-8">
          <h3 className="text-2xl font-bold mb-2">The ReferenceSpeak Advantage</h3>
          <p className="text-gray-600">Small investment. Big opportunities.</p>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="bg-white p-6 rounded-lg">
            <div className="flex items-center justify-center mb-4">
              <div className="bg-green-100 p-3 rounded-full">
                <DollarSign className="text-green-600 h-6 w-6" />
              </div>
            </div>
            <h4 className="text-center font-bold mb-2">Investment</h4>
            <div className="text-center">
              <p className="text-sm text-gray-600 mb-2">8-minute reference call</p>
              <p className="text-2xl font-bold text-green-600">~$12</p>
              <p className="text-xs text-gray-500">$1.50/minute</p>
            </div>
          </div>

          <div className="relative bg-white p-6 rounded-lg">
            <div className="flex items-center justify-center mb-4">
              <div className="bg-blue-100 p-3 rounded-full">
                <Shield className="text-blue-600 h-6 w-6" />
              </div>
            </div>
            <h4 className="text-center font-bold mb-2">You Get</h4>
            <ul className="text-sm space-y-2">
              <li className="flex items-center gap-2">
                <Check className="text-green-500 h-4 w-4" />
                <span>Professionally verified reference</span>
              </li>
              <li className="flex items-center gap-2">
                <Check className="text-green-500 h-4 w-4" />
                <span>Secure, instant sharing</span>
              </li>
              <li className="flex items-center gap-2">
                <Check className="text-green-500 h-4 w-4" />
                <span>Reuse anytime</span>
              </li>
            </ul>
          </div>

          <div className="bg-white p-6 rounded-lg">
            <div className="flex items-center justify-center mb-4">
              <div className="bg-purple-100 p-3 rounded-full">
                <Trophy className="text-purple-600 h-6 w-6" />
              </div>
            </div>
            <h4 className="text-center font-bold mb-2">Potential Return</h4>
            <ul className="text-sm space-y-2">
              <li className="flex items-center gap-2">
                <ArrowRight className="text-purple-500 h-4 w-4" />
                <span>Faster interview process</span>
              </li>
              <li className="flex items-center gap-2">
                <ArrowRight className="text-purple-500 h-4 w-4" />
                <span>Stand out in applications</span>
              </li>
              <li className="flex items-center gap-2">
                <ArrowRight className="text-purple-500 h-4 w-4" />
                <span>Win more opportunities</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="space-y-6">
          {/* Progress Steps */}
          <div className="flex justify-between">
            <div className={`flex items-center ${step >= 1 ? 'text-blue-600' : 'text-gray-400'}`}>
              <Phone className="w-6 h-6 mr-2" />
              <span>Manager Calls</span>
            </div>
            <div className={`flex items-center ${step >= 2 ? 'text-blue-600' : 'text-gray-400'}`}>
              <Check className="w-6 h-6 mr-2" />
              <span>Agent Interview</span>
            </div>
            <div className={`flex items-center ${step >= 3 ? 'text-blue-600' : 'text-gray-400'}`}>
              <Star className="w-6 h-6 mr-2" />
              <span>Verification</span>
            </div>
          </div>
          
          {/* Demo Controls */}
         
          
          {/* Step Content */}
          <div className="border rounded-lg p-4">
            {step === 1 && (
              <div className="space-y-4">
                <h3 className="font-bold">Step 1: Manager Reference Call</h3>
                <p>Manager clicks link to speak with AI interviewer at their convenience</p>
                <div className="flex items-center text-green-600">
                  <Clock className="w-4 h-4 mr-2" />
                  <span>Average call time: 8 minutes</span>
                </div>
              </div>
            )}
            
            {step === 2 && (
              <div className="space-y-4">
                <h3 className="font-bold">Step 2: AI Interview</h3>
                <p>AI conducts structured interview and records key points</p>
                <div className="flex items-center text-blue-600">
                  <Award className="w-4 h-4 mr-2" />
                  <span>Consistent, unbiased questions</span>
                </div>
              </div>
            )}
            
            {step === 3 && (
              <div className="space-y-4">
                <h3 className="font-bold">Step 3: Verification Complete</h3>
                <p>Reference verified and ready for future employers</p>
                <div className="flex items-center text-purple-600">
                  <Star className="w-4 h-4 mr-2" />
                  <span>Professional profile enhanced</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="flex justify-center space-x-4 mt-6 mb-16">
            <button 
              onClick={() => setStep(prev => Math.max(1, prev - 1))}
              className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
            >
              Previous
            </button>
            <button 
              onClick={() => setStep(prev => Math.min(3, prev + 1))}
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
            >
              Next Step
            </button>
          </div>

      {/* Social Proof */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-20 mt-12">
      <div className="bg-white p-6 rounded-lg shadow">
              <p className="italic">"Got three interviews after sharing my references. The voice references made all the difference."</p>
              <p className="font-bold mt-4">- Sarah K., Sales Professional</p>
            </div>
        <div className="bg-white p-6 rounded-lg shadow">
            <p className="italic">"Easy for my references, impressive to potential clients. Worth every penny."</p>
            <p className="font-bold mt-4">- Mark R., Contractor</p>
          </div>
      </div>

      

     

       {/* Success Stories */}
       <div className="mb-16 p-8 bg-gray-50">
        <h3 className="text-xl font-bold mb-6 text-center">References That Work</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-white p-6 rounded-lg">
            <div className="flex items-center gap-3 mb-4">
              <Star className="text-yellow-500" />
              <p className="font-medium">Cold Application → Interview</p>
            </div>
            <p className="text-sm text-gray-600 mb-3">"Added my verified references to my application. Got called for an interview the next day. They said my references really stood out."</p>
            <p className="text-green-500 text-sm">$12 → Dream job interview</p>
          </div>
          
          <div className="bg-white p-6 rounded-lg">
            <div className="flex items-center gap-3 mb-4">
              <Star className="text-yellow-500" />
              <p className="font-medium">Client Pitch → Contract</p>
            </div>
            <p className="text-sm text-gray-600 mb-3">"Shared my verified client references in my proposal. Won a $20k contract because they could hear real feedback."</p>
            <p className="text-green-500 text-sm">$12 → $20k contract</p>
          </div>
        </div>
      </div>

      {/* Calculation Header */}
      <div className="text-center mb-12">
        <div className="bg-blue-100 text-blue-800 px-4 py-2 rounded-full inline-block mb-6">
          Most references complete in 8 minutes
        </div>
        <h2 className="text-3xl font-bold mb-4">Simple Per-Minute Pricing</h2>
        <p className="text-xl text-gray-600 mb-6">Pay only for the time you use</p>
      </div>

      {/* Calculator */}
      <div className="max-w-xl mx-auto mb-16 bg-white rounded-lg shadow-lg p-6">
        <div className="space-y-6">
          <div>
            <div className="flex justify-between mb-2">
              <label className="text-sm font-medium">
                Reference Length
              </label>
              <span className="text-sm text-blue-600 font-medium">
                ${RATE_PER_MINUTE}/minute
              </span>
            </div>
            <input 
              type="range" 
              min="4" 
              max="12" 
              value={minutes}
              onChange={(e) => setMinutes(parseInt(e.target.value))}
              className="w-full accent-blue-600"
            />
            <div className="flex justify-between mt-2">
              <span className="text-sm text-gray-600">4 min</span>
              <span className="text-sm text-gray-600">8 min</span>
              <span className="text-sm text-gray-600">12 min</span>
            </div>
          </div>

          <div className="bg-blue-50 p-6 rounded-lg">
            <div className="flex items-center justify-between mb-4">
              <div className="text-sm text-gray-600">
                <Clock className="w-4 h-4 inline mr-1" />
                {minutes} minute reference
              </div>
              <div className="text-2xl font-bold text-blue-600">
                ${calculateTotal(minutes)}
              </div>
            </div>
            <div className="text-sm text-gray-600 border-t pt-4">
              Example: 5 references × ${calculateTotal(minutes)} = ${(Number(calculateTotal(minutes)) * 5).toFixed(2)}
            </div>
          </div>

          <div className="space-y-3">
            <div className="flex items-center space-x-3">
              <Check className="w-5 h-5 text-green-600" />
              <span>Pay per minute, nothing wasted</span>
            </div>
            <div className="flex items-center space-x-3">
              <Check className="w-5 h-5 text-green-600" />
              <span>Most references complete in 8 minutes</span>
            </div>
            <div className="flex items-center space-x-3">
              <Check className="w-5 h-5 text-green-600" />
              <span>No charge if reference doesn't complete</span>
            </div>
          </div>

          <button className="w-full bg-blue-600 text-white py-3 rounded-lg hover:bg-blue-700">
            Start Verification
          </button>
        </div>
      </div>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        {selected === 'call' ? <CallExperience /> : <Invite />}
      </Modal>

      {/* CTA */}
      <div className="text-center">
        <button className="bg-blue-600 hover:bg-blue-700 text-white px-8 py-4 rounded-lg text-lg">
          Get Started Now → $12
        </button>
        <p className="mt-4 text-sm text-gray-600">No subscription. Pay per reference.</p>
      </div>
    </div>
    </>
  );
};

export default ReferenceCheck;
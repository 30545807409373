import React from 'react';
import { Shield, Check, ArrowRight, Star, Building, Trophy, Clock, DollarSign, Phone } from 'lucide-react';

const LandingPage = () => {
  return (
    <div className="max-w-5xl mx-auto px-4 py-8">
      {/* Hero Section */}
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold mb-4">Collect Once. Open Doors Anywhere.</h1>
        <p className="text-xl mb-6">Your references, professionally verified, ready to create opportunities</p>
      </div>

      {/* Investment Value Calculator */}
      <div className="p-6 mb-16 bg-blue-50">
        <div className="text-center mb-8">
          <h3 className="text-2xl font-bold mb-2">The ReferenceSpeak Advantage</h3>
          <p className="text-gray-600">Small investment. Big opportunities.</p>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="bg-white p-6 rounded-lg">
            <div className="flex items-center justify-center mb-4">
              <div className="bg-green-100 p-3 rounded-full">
                <DollarSign className="text-green-600 h-6 w-6" />
              </div>
            </div>
            <h4 className="text-center font-bold mb-2">Investment</h4>
            <div className="text-center">
              <p className="text-sm text-gray-600 mb-2">8-minute reference call</p>
              <p className="text-2xl font-bold text-green-600">~$12</p>
              <p className="text-xs text-gray-500">$1.50/minute</p>
            </div>
          </div>

          <div className="relative bg-white p-6 rounded-lg">
            <div className="flex items-center justify-center mb-4">
              <div className="bg-blue-100 p-3 rounded-full">
                <Shield className="text-blue-600 h-6 w-6" />
              </div>
            </div>
            <h4 className="text-center font-bold mb-2">You Get</h4>
            <ul className="text-sm space-y-2">
              <li className="flex items-center gap-2">
                <Check className="text-green-500 h-4 w-4" />
                <span>Professionally verified reference</span>
              </li>
              <li className="flex items-center gap-2">
                <Check className="text-green-500 h-4 w-4" />
                <span>Secure, instant sharing</span>
              </li>
              <li className="flex items-center gap-2">
                <Check className="text-green-500 h-4 w-4" />
                <span>Reuse anytime</span>
              </li>
            </ul>
          </div>

          <div className="bg-white p-6 rounded-lg">
            <div className="flex items-center justify-center mb-4">
              <div className="bg-purple-100 p-3 rounded-full">
                <Trophy className="text-purple-600 h-6 w-6" />
              </div>
            </div>
            <h4 className="text-center font-bold mb-2">Potential Return</h4>
            <ul className="text-sm space-y-2">
              <li className="flex items-center gap-2">
                <ArrowRight className="text-purple-500 h-4 w-4" />
                <span>Faster interview process</span>
              </li>
              <li className="flex items-center gap-2">
                <ArrowRight className="text-purple-500 h-4 w-4" />
                <span>Stand out in applications</span>
              </li>
              <li className="flex items-center gap-2">
                <ArrowRight className="text-purple-500 h-4 w-4" />
                <span>Win more opportunities</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* The Journey */}
      <div className="mb-16">
        <h3 className="text-2xl font-bold mb-8 text-center">Your Journey to Success</h3>
        <div className="relative">
          <div className="absolute top-1/2 left-0 right-0 h-1 bg-blue-200 -z-10 hidden md:block" />
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="p-6 bg-white">
              <div className="space-y-4">
                <div className="bg-blue-100 w-12 h-12 rounded-full flex items-center justify-center mb-4">
                  <span className="text-xl font-bold">1</span>
                </div>
                <h4 className="font-bold">Easy Collection</h4>
                <div className="space-y-3">
                  <div className="flex items-center gap-2 text-sm">
                    <Phone className="text-blue-500 h-4 w-4" />
                    <span>Send your reference a quick link</span>
                  </div>
                  <div className="flex items-center gap-2 text-sm">
                    <Clock className="text-blue-500 h-4 w-4" />
                    <span>They answer when convenient</span>
                  </div>
                  <div className="flex items-center gap-2 text-sm">
                    <Shield className="text-blue-500 h-4 w-4" />
                    <span>Professional verification</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="p-6 bg-white">
              <div className="space-y-4">
                <div className="bg-blue-100 w-12 h-12 rounded-full flex items-center justify-center mb-4">
                  <span className="text-xl font-bold">2</span>
                </div>
                <h4 className="font-bold">Your Reference Library</h4>
                <div className="space-y-3">
                  <div className="bg-gray-50 p-3 rounded text-sm">
                    <div className="flex items-center gap-2">
                      <Shield className="text-green-500 h-4 w-4" />
                      <span>Senior Manager Reference</span>
                    </div>
                  </div>
                  <div className="bg-gray-50 p-3 rounded text-sm">
                    <div className="flex items-center gap-2">
                      <Shield className="text-green-500 h-4 w-4" />
                      <span>Client Project Reference</span>
                    </div>
                  </div>
                  <div className="bg-gray-50 p-3 rounded text-sm">
                    <div className="flex items-center gap-2">
                      <Shield className="text-green-500 h-4 w-4" />
                      <span>Team Lead Reference</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="p-6 bg-white">
              <div className="space-y-4">
                <div className="bg-blue-100 w-12 h-12 rounded-full flex items-center justify-center mb-4">
                  <span className="text-xl font-bold">3</span>
                </div>
                <h4 className="font-bold">Open Doors</h4>
                <div className="space-y-3">
                  <div className="bg-green-50 p-3 rounded text-sm">
                    <p className="font-medium">"Applying for Dream Job"</p>
                    <p className="text-green-600">→ Interview scheduled</p>
                  </div>
                  <div className="bg-green-50 p-3 rounded text-sm">
                    <p className="font-medium">"Pitching New Client"</p>
                    <p className="text-green-600">→ Meeting booked</p>
                  </div>
                  <div className="bg-green-50 p-3 rounded text-sm">
                    <p className="font-medium">"Cold Outreach"</p>
                    <p className="text-green-600">→ Call scheduled</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Success Stories */}
      <div className="mb-16 p-8 bg-gray-50">
        <h3 className="text-xl font-bold mb-6 text-center">References That Work</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="bg-white p-6 rounded-lg">
            <div className="flex items-center gap-3 mb-4">
              <Star className="text-yellow-500" />
              <p className="font-medium">Cold Application → Interview</p>
            </div>
            <p className="text-sm text-gray-600 mb-3">"Added my verified references to my application. Got called for an interview the next day. They said my references really stood out."</p>
            <p className="text-green-500 text-sm">$12 → Dream job interview</p>
          </div>
          
          <div className="bg-white p-6 rounded-lg">
            <div className="flex items-center gap-3 mb-4">
              <Star className="text-yellow-500" />
              <p className="font-medium">Client Pitch → Contract</p>
            </div>
            <p className="text-sm text-gray-600 mb-3">"Shared my verified client references in my proposal. Won a $20k contract because they could hear real feedback."</p>
            <p className="text-green-500 text-sm">$12 → $20k contract</p>
          </div>
        </div>
      </div>

      {/* CTA */}
      <div className="text-center">
        <button className="bg-blue-600 hover:bg-blue-700 text-white px-8 py-4 rounded-lg text-lg">
          Start Your Reference Library
        </button>
        <p className="mt-4 text-sm text-gray-600">Just $1.50/minute • Average call: 8 minutes • No subscription</p>
      </div>
    </div>
  );
};

export default LandingPage;
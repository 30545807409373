interface AccessCodeResponse {
    success: boolean;
    data?: {
      accessCode: string;
      managerName: string;
      employeeLastName: string;
      authenticationStatus: string;
      agentNextStep: string;
      gigs: {
        name: string;
        role: string;
        company: string;
        dates: string;
        roleDescription: string;
        accessCode: string;
      }[];
    };
    error?: string;
  }
  
  export const accessCodeTool = {
    name: 'get_access_code',
    description: 'Retrieves access code for a specific manager using their full name and access code',
    parameters: {
      type: 'object',
      properties: {
        managerName: {
          type: 'string',
          description: 'Full name of the manager'
        },
        accessCode: {
          type: 'string',
          description: 'Access code to verify the manager'
        },
        employeeLastName: {
          type: 'string',
          description: 'Last name of the person being referenced'
        }
      },
      required: ['managerName', 'accessCode', 'employeeLastName']
    }
  };
  
  export const createAccessCodeHandler = async ({ 
    managerName, 
    accessCode,
    employeeLastName,

  }: { 
    managerName: string; 
    accessCode: string;
    employeeLastName: string;

  }): Promise<AccessCodeResponse> => {
    try {
      // Validate access code and employee name
      if (accessCode !== 'SW822') {
        return {
          success: false,
          data: {
            authenticationStatus: 'error',
            agentNextStep: "Submit valid code",
            accessCode: accessCode !== 'SW822' ? '**********' : 'SW822',
            managerName: managerName !== 'John Leguizamo' ? '**********' : 'John Leguizamo',
            employeeLastName: employeeLastName !== 'Burley' ? '**********' : 'Burley',
            gigs: []
          }
        };
      }
      // Success case remains the same
      return {
        success: true,
        data: {
          authenticationStatus: 'success',
          agentNextStep: 'Begin Interview',
          accessCode: 'SU822',
          managerName: 'John Leguizamo',
          employeeLastName: 'Burley',
          gigs: [{
            name: 'Cam Burley',
            role: 'AI Engineer',
            company: 'Trauma Journal',
            dates: 'Feb 2023 — Jan 2024',
            roleDescription: 'Engineered HIPAA-compliant journaling system with Azure OpenAI and Pinecones secure infrastructure. Implemented comprehensive checkpoint system for privacy-conscious response validation. Built reusable components for secure data handling and encryption. Leveraged Langsmith for monitoring completion quality while maintaining strict privacy requirements.',
            accessCode: '543210'
          },
          {
            name: 'Cam Burley',
            role: 'AI Lead Consultant',
            company: 'Astra',
            dates: 'March 2023 — October 2023',
            roleDescription: 'Architected scalable horoscope system using LangChain for model-agnostic orchestration between OpenAI and Anthropic. Built validation/reflection nodes ensuring response quality across 2K daily WhatsApp interactions. Implemented cloud prompts with Langfuse for rapid iteration, enabling seamless prompt optimization without code deployments. Developed reusable components for scheduling and user memory persistence.',
            accessCode: '123450'
          }]
        }
      };
    } catch (error) {
      console.error('Error fetching access code:', error);
      return {
        success: false,
        error: error instanceof Error ? error.message : 'Failed to fetch access code'
      };
    }
  };
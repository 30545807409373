import React, { useState } from 'react';
import { Mail, Shield, ArrowRight, Check, Copy } from 'lucide-react';

const Invite = () => {
  const [inviteSent, setInviteSent] = useState(false);
  const [referrerEmail, setReferrerEmail] = useState('');
  const [copied, setCopied] = useState(false);
  const [trial, setTrial] = useState(false);
  const verificationCode = '12345-ABC';

  const copyCode = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div className="max-w-3xl mx-auto p-6">
      {/* Invitation Form */}
      <div className="bg-white rounded-lg shadow-lg overflow-hidden mb-8">
        <div className="bg-gradient-to-r from-blue-600 to-blue-700 p-6 text-white">
          <h2 className="text-2xl font-bold mb-2">Invite a Reference</h2>
          <p>Send a secure invitation to your past supervisor</p>
        </div>

        <div className="p-6 space-y-6">
          {!inviteSent && trial ? (
            <form className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Referrer's Work Email
                </label>
                <input
                  type="email"
                  className="w-full p-2 border rounded"
                  placeholder="supervisor@company.com"
                  value={referrerEmail}
                  onChange={(e) => setReferrerEmail(e.target.value)}
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Your Relationship
                </label>
                <select className="w-full p-2 border rounded">
                  <option>Direct Supervisor</option>
                  <option>Project Manager</option>
                  <option>Team Lead</option>
                  <option>Client</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Time Period
                </label>
                <div className="grid grid-cols-2 gap-4">
                  <input
                    type="text"
                    className="p-2 border rounded"
                    placeholder="Start Date"
                  />
                  <input
                    type="text"
                    className="p-2 border rounded"
                    placeholder="End Date"
                  />
                </div>
              </div>

              <button
                type="button"
                onClick={() => setInviteSent(true)}
                className="w-full bg-blue-600 text-white py-2 rounded hover:bg-blue-700"
              >
                Send Invitation
              </button>
            </form>
          ) : (
            <div className="text-center">
              <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-4">
                <Check className="w-8 h-8 text-green-600" />
              </div>
              <h3 className="text-xl font-semibold mb-2">Invitation Sent!</h3>
              <p className="text-gray-600 mb-4">
                We've sent the invitation to {referrerEmail}
              </p>
              <div className="bg-gray-50 p-4 rounded">
                <div className="text-sm font-medium text-gray-700 mb-2">
                  Verification Code
                </div>
                <div className="flex items-center justify-center space-x-2">
                  <code className="bg-white px-4 py-2 rounded border">
                    {verificationCode}
                  </code>
                  <button
                    onClick={copyCode}
                    className="flex items-center space-x-1 text-blue-600 hover:text-blue-700"
                  >
                    {copied ? (
                      <Check className="w-4 h-4" />
                    ) : (
                      <Copy className="w-4 h-4" />
                    )}
                    <span>{copied ? 'Copied!' : 'Copy'}</span>
                  </button>
                </div>
                <p className="text-sm text-gray-600 mt-2">
                  Your referrer will need this code when they call
                </p>
              </div>

              {/* Preview Email */}
              <div className="mt-6 text-left border rounded p-4">
                <div className="text-sm font-medium text-gray-700 mb-2">
                  Preview of Email Sent
                </div>
                <div className="bg-white p-4 rounded border">
                  <p className="mb-4">Hi there,</p>
                  <p className="mb-4">
                    {referrerEmail.split('@')[0]} Someone has requested a reference from you through our secure verification system.
                  </p>
                  <div className="bg-blue-50 p-4 rounded mb-4">
                    <h4 className="font-semibold mb-2">Next Steps:</h4>
                    <ol className="list-decimal list-inside space-y-2 text-sm">
                      <li><a href="#" className="text-blue-600 underline hover:text-blue-700">Click this link to call ReferenceSpeak AI at your convenience</a></li>
                      <li>Enter verification code: {verificationCode}</li>
                      <li>Share your feedback in a brief conversation</li>
                    </ol>
                  </div>
                  <p className="text-sm text-gray-600">
                    Average call time is 8 minutes. Your feedback will be recorded and verified.
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Invite;
